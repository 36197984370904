.btn--icon.btn-sm {
	width: 2rem;
	height: 2rem;
}

.zmdi.toggle-password{
	float: right;
	position: relative;
	margin-top: -25px;
	font-size: 23px;
	color: $blue;

	&:hover {
		cursor: pointer;
	}
}