.colorpicker {
	padding: 5px;
	min-width: 130px !important;

	&:before,
	&:after {
		display: none;
	}

	div {
		border-radius: $border-radius;
	}

	&.colorpicker-horizontal {
		width: 160px;

		.colorpicker-hue,
		.colorpicker-color {
			width: 100%;
		}
	}
}

.colorpicker-alpha {
	display: none !important;
}

.colorpicker-saturation {
	i {
		border: 0;
		box-shadow: 0 0 5px rgba($black, 0.36);

		&,
		b {
			height: 10px;
			width: 10px;
		}
	}
}

.colorpicker-hue {
	width: 15px;
}

.colorpicker-color {

	&,
	div {
		height: 15px;
	}
}

.color-picker__preview {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 20px;
	height: 20px;
	border-radius: 50%;
}