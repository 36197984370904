@mixin select-bg() {
  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: 1;
    right: 0;
    bottom: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 8px;
    border-color: transparent transparent #d1d1d1 transparent;
  }
}