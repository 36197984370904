
.navigation__sub--toggled .navigation__sub--active{

    a {
        background-color: $navigation-link-hover-bg;
    }
}

.navigation .navigation__sub--active {

    a {
        background-color:  $navigation-link-hover-bg;
    }

    &.navigation__sub--toggled {
        a {
            background-color: inherit;
        }
        
        .navigation__sub--active a {
            background-color: $navigation-link-hover-bg;
        }
    }
}

#right-mobile-menu-wrapper {
    opacity: 0;
    background-color: $white;
    position: fixed;
    top: 70px;
    right: -300px;
    z-index: 19;
    box-shadow: -5px 0 10px rgba(39, 87, 110, 0.08);
    height: calc(100vh - 70px);
    transition: right 300ms, opacity 300ms;

}


#right-mobile-menu-wrapper.toggle-right-mobile-menu {
    opacity: 1;
    right: 0px;
}

.expanded-menu {
    max-height: calc(100vh - 70px) !important;
    min-width: 0px !important;
    width: 250px !important;
    margin-right: -5px;


    ul {
        list-style: none;

        .active {
            background-color: rgba(0, 0, 0, 0.05);
        }
    
        li {

            p {
                padding-left: 2.5rem;
            }

            .active {
                background-color: rgba(0, 0, 0, 0.05);
            }

            a{
                display: block;
                width: 100%;
                height: 45px;
                padding: 1rem 2.5rem;
                text-align: left;
                color: $black;

                i{
                    margin-right: 0.6rem;
                    font-size: 1.5rem;
                    line-height: 100%;
                    text-align: left;
                }
            }
        }
    }

    
}
