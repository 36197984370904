.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.2rem;
}

.login__block {
  max-width: 330px;
  width: 100%;
  display: none;
  text-align: center;
  padding: 1.2rem;
  @include animate(fadeInUp, 300ms);
  @include card();

  @include media-breakpoint-up(sm) {
    &:hover {
      .login__block__actions .dropdown {
        display: block;
      }
    }
  }
}

.login__block.active {
  z-index: 10;
  display: inline-block;
}

.login__block__header {
  padding: 1.5rem;
  margin-top: -2.4rem;
  position: relative;
  color: $white;
  border-radius: $border-radius;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.18);

  & > i,
  & > img {
    display: block;
    margin-bottom: 0.8rem;
  }

  & > i {
    font-size: 3rem;
  }

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.33);
  }
}

.login__block__actions {
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  .dropdown:not(.show) {
    display: none;
  }
}

.login__block__body {
  padding: 1rem;
}

.login__block__btn {
  margin-top: 0.5rem;

  &,
  &:hover,
  &:focus {
    color: $white;
  }

  &:hover {
    opacity: 0.9;
  }
}