.dbpassword {
    padding-right: 23px;
}

.hide {
	display: none !important;
}

.noUi-horizontal .noUi-tooltip {
	top: 120%;
	bottom: unset;
}