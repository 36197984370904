.sortable li {
	position: relative;

	.handle {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}
}

.filter-list {
	min-width: 250px;
	max-height: 300px;
	overflow-y: auto;
}