.error {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.error__inner {
  max-width: 600px;
  width: 100%;
  padding: 1rem;
  text-align: center;

  & > h1 {
    font-size: 8rem;
    font-weight: bold;
    color: $white;
    text-shadow: 0 0 10px rgba($black, 0.03);
    line-height: 100%;
    margin-bottom: 1.5rem;
  }

  & > h2 {
    font-weight: normal;
    margin: 1.3rem 0;
    font-size: 1.5rem;
  }
}