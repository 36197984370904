th.checkbox,
td.checkbox {
  width: 5.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

th {
  .select2-container {
    width: auto !important;

    .select2-selection--single {
      .select2-selection__rendered {
        padding: 0 15px 0 0;
      }
    }
  }
}

input.link {
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  background: $white;
}

.badge {
  color: #fff;
}
