@keyframes spinner {
	to { transform: rotate(360deg); }
}
.spinner:before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	margin-top: -40px;
	margin-left: -20px;
	border-radius: 50%;
	border: 2px solid #ccc;
	border-top-color: #000;
	animation: spinner .6s linear infinite;
}