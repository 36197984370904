.alert-success,
.alert-danger,
.alert-info,
.alert-warning {
  .alert-link {
    color: $white;
  }
}

.alert-link {
  border-bottom: 1px solid rgba($white, 0.25);
}

.alert-dismissible {
  .close {
    opacity: 0.5;

    &:hover {
      color: $white;
      opacity: 1;
    }
  }
}

.alert-heading {
  font-size: 1.1rem;
}

// Notify
.alert--notify {
  max-width: 600px;
  width: calc(100% - 30px);
  padding-right: 80px;
  box-shadow: 0 3px 10px rgba($black, 0.15);
  color: rgba($white, 0.85);

  &:not(.alert-info):not(.alert-success):not(.alert-warning):not(.alert-danger) {
    background-color: rgba($black, 0.95);
    .alert--notify__close {
      color: $yellow;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}


.alert--notify__close {
  background-color: transparent;
  border: 0;
  text-transform: uppercase;
  padding: 0;
  cursor: pointer;
  font-weight: $font-weight-bold;
  position: absolute;
  right: $alert-padding-x;
  top: $alert-padding-y;
  color: $white;
}