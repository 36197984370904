// Options
$enable-transitions: true;
$enable-shadows: false;

// Colors
$gray-50: #ede9e6;
$gray-100: darken($gray-50, 5%);
$gray-200: darken($gray-100, 5%);
$gray-300: darken($gray-200, 5%);
$gray-400: darken($gray-300, 5%);
$gray-500: lighten($gray-600, 5%);
$gray-600: lighten($gray-700, 5%);
$gray-700: lighten($gray-800, 5%);
$gray-800: lighten($gray-900, 5%);
$gray-900: #231f20;

$primary: #231f20;
$secondary: #231f20;
$red: #f3928b;
$blue: #60728f;
$green: #65af73;
$orange: #e5c491;
$black: $gray-900;
$white: $gray-50;
$light-gray: $gray-100;
$gray: $gray-200;

$blue-grey: #e7e3df;
$light-blue: lighten($blue, 20%);
$amber: $orange;
$brown: $orange;

$colors: (
  'white': $white,
  'black': $black,
  'red': $red,
  'blue': $blue,
  'green': $green,
  'orange': $orange,
  'gray': $gray,
);

// Theme Colors
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $green,
  info: $blue,
  warning: $orange,
  danger: $red,
);

$yiq-text-dark: $black;

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$text-muted: #9c9c9c;
$headings-color: $black;
$font-weight-bold: 500;
$font-family-icon: 'Material-Design-Iconic-Font';
$font-size-root: 13.5px;

// Links
$link-color: $blue;
$link-hover-decoration: none;

// Body
$body-bg: $gray-200;
$body-color: $black;

// Form
$input-bg: transparent;
$input-disabled-bg: transparent;
$input-box-shadow: rgba($black, 0);
$input-border-color: lighten($gray-200, 1%);
$input-focus-border-color: $input-border-color;
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$form-group-margin-bottom: 2rem;
$input-focus-box-shadow: none;
$input-padding-x: 0;
$input-padding-x-lg: $input-padding-x;
$input-padding-x-sm: $input-padding-x;

// Checbox and Radio
$checkbox-radio-size: 18px;
$checkbox-radio-background-color: $white;
$checkbox-radio-checked-background-color: $blue;
$checkbox-radio-border-color: #6e6e6e;
$checkbox-radio-checked-border-color: $checkbox-radio-checked-background-color;

// Layout
$content-title-heading-color: #676767;

// Header
$header-height: 72px;
$header-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15);
$header-z-index: 20;

// Dropdown
$dropdown-border-width: 0;
$dropdown-border-color: transparent;
$dropdown-box-shadow: 0 4px 18px rgba($black, 0.11);
$dropdown-link-color: #6d6d6d;
$dropdown-link-active-color: $dropdown-link-color;
$dropdown-link-active-bg: $gray-100;
$dropdown-link-disabled-color: $gray-500;
$dropdown-link-hover-bg: $gray-50;
$dropdown-padding-y: 0.8rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-item-padding-y: 0.5rem;
$dropdown-spacer: 0;
$dropdown-divider-bg: $gray-100;
$dropdown-header-color: $gray-500;

// Border Radius
$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;

// List Group
$list-group-bg: transparent;
$list-group-border-width: 0;
$list-group-hover-bg: black;
$list-group-active-bg: $blue;
$list-group-item-padding-x: 2rem;
$list-group-item-padding-y: 1rem;

// Progress Bar
$progress-box-shadow: none;
$progress-bar-color: $blue;
$progress-bg: $gray-200;
$progress-height: 5px;

// Card
$card-inner-border-radius: $border-radius;
$card-border-color: transparent;
$card-border-radius: $border-radius;
$card-spacer-x: 2.2rem;
$card-spacer-y: 2.1rem;
$card-cap-bg: transparent;
$card-img-overlay-padding: 0;
$card-shadow: 0 1px 2px rgba($black, 0.075);
$card-columns-margin: 2.3rem;
$card-header-bg: $gray-50;

// Sidebars
$sidebar-width: 270px;
$sidebar-z-index: $header-z-index - 1;
$navigation-link-color: $body-color;
$navigation-link-active-color: $white;
$navigation-link-hover-bg: rgba(0, 0, 0, 0.05);

// Tabels
$table-border-color: lighten($gray-200, 3%);
$table-dark-bg: #313a44;
$table-dark-border-color: #3e464e;
$table-dark-color: #f3f3f3;
$table-accent-bg: $table-border-color;
$table-hover-bg: $table-accent-bg;
$table-cell-padding: 1rem 1.5rem;
$table-cell-padding-sm: 0.75rem 1rem;
$table-head-bg: lighten($gray-200, 3%);

// Pagination
$pagination-border-width: 0;
$pagination-padding-y: 0;
$pagination-padding-x: 0;
// $pagination-bg: rgba(0, 0, 0, 0.05);
// $pagination-hover-bg: rgba(0, 0, 0, 0.1);
$pagination-bg: $white;
$pagination-hover-bg: darken($white, 10%);
$pagination-color: $black;
$pagination-hover-color: darken($pagination-color, 5%);
$pagination-disabled-bg: $pagination-bg;
$pagination-disabled-color: rgba($pagination-color, 0.4);
$pagination-active-bg: $secondary;

// Code
$pre-color: $white;
$pre-bg: $gray-800;
$pre-border-color: $pre-bg;

// Alert
$alert-link-font-weight: normal;
$alert-border-width: 0;
$alert-padding-x: 1.5rem;
$alert-padding-y: 1.1rem;
$alert-bg-level: 0;
$alert-color-level: -12.5;

// Close
$close-font-weight: normal;
$close-text-shadow: none;

// Badges
$badge-padding-y: 0.5rem;
$badge-padding-x: 1rem;
$badge-font-size: 90%;
$badge-font-weight: 500;
$badge-pill-padding-x: $badge-padding-x;
$badge-pill-padding-y: $badge-padding-y;

// Breadcrumbs
$breadcrumb-divider: '\f30f';
$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0.25rem;

// Carosuel
$carousel-control-prev-icon-bg: none;
$carousel-control-next-icon-bg: none;
$carousel-control-icon-width: 40px;
$carousel-control-opacity: 0.8;
$carousel-caption-color: rgba($white, 0.9);

// Modal
$modal-backdrop-opacity: 0.2;
$modal-content-box-shadow-xs: 0 5px 20px rgba($black, 0.07);
$modal-content-box-shadow-sm-up: $modal-content-box-shadow-xs;
$modal-content-border-width: 0;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-header-padding: 25px 30px 0;
$modal-inner-padding: 25px 30px;
$modal-lg: 1000px;

// Popovers
$popover-border-color: transparent;
$popover-box-shadow: 0 2px 30px rgba($black, 0.1);
$popover-body-padding-y: 1.25rem;
$popover-body-padding-x: 1.5rem;
$popover-header-padding-x: $popover-body-padding-x;
$popover-header-padding-y: $popover-body-padding-y;
$popover-header-bg: $white;

// Tabs
$nav-tabs-border-width: 2px;
$nav-tabs-border-color: $gray-100;
$nav-tabs-border-radius: 0;
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 1.2rem;
$nav-link-padding: 1rem 1.2rem;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-color: inherit;

// Tooltips
$tooltip-padding-y: 0.7rem;
$tooltip-padding-x: 1.1rem;
$tooltip-bg: $gray-700;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-opacity: 1;

// Backdrop
$backdrop-z-index: 100;

// Listview
$listview-item-active-bg: $gray-50;
$listview-item-hover-bg: $listview-item-active-bg;
$listview-item-striped-bg: #f9f9f9;
$listview-invert-item-active-bg: rgba($white, 0.025);
$listview-invert-item-hover-bg: rgba($white, 0.025);
$listview-invert-item-striped-bg: rgba($white, 0.1);
$listview-border-color: $gray-100;

// HR
$hr-border-color: $gray-200;

// Input Group
$input-group-addon-bg: $white;

// Jumbotron
$jumbotron-bg: $white;

// Tree View
$treeview-item-border-color: #f1f4f7;
$treeview-item-active-border-color: $gray-50;
$treeview-item-active-bg: $treeview-item-active-border-color;
$treeview-item-hover-bg: lighten($gray-50, 1%);

// YIQ Contrast
$yiq-contrasted-threshold: 200;

// Buttons
$btn-focus-width: 0;
$btn-border-width: 2px;

// Accordion
$accordion-border-color: #eee;
$accordion-padding: 0.85rem 1.35rem;
