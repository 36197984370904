.actions {
	.select2-container .select2-selection--single {
		.select2-selection__rendered {
			background-color: $white;
			padding: 7px 10px;
			border-radius: 2px;
			color: $black;
		}

		&:before {
			right: 2px;
		}
	}

	input[type="text"],
	input[type="number"] {
		background: $white;
		color: $black;
	}

	.input-group {
		margin: 0;
		width: auto;
	}

	.date-picker {
		border: 1px solid $input-border-color;
	}
}